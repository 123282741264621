import React from "react";
import ReactDOM from "react-dom/client";
import App from "./Components/App";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBLoMDrPMITPf_5eMhzHuyIAOg_d3_AzvQ",
  authDomain: "suite-venture-housing.firebaseapp.com",
  projectId: "suite-venture-housing",
  storageBucket: "suite-venture-housing.appspot.com",
  messagingSenderId: "493777094960",
  appId: "1:493777094960:web:116b62b24df951346820f5",
  measurementId: "G-EPC5F2C8MW",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export { app, auth };

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
