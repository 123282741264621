import React, { useState, useEffect } from "react";
import { getStorage, ref, listAll, getDownloadURL } from "firebase/storage";
import { auth } from "../../index";
import { useSearchParams } from "react-router-dom";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { LatLngTuple } from "leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "./Property.css";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

L.Marker.prototype.options.icon = L.icon({
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const Property: React.FC = () => {
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [searchParams] = useSearchParams();
  const folderName = searchParams.get("folderName") || "";

  useEffect(() => {
    if (folderName) {
      fetchImageUrls(folderName);
    }
  }, [folderName]);

  const fetchImageUrls = async (folder: string) => {
    const storage = getStorage(auth.app);
    const folderRef = ref(storage, "images/" + folder);
    const files = await listAll(folderRef);
    const urls = await Promise.all(
      files.items.map(async (item) => {
        return await getDownloadURL(item);
      })
    );
    setImageUrls(urls);
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % imageUrls.length);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? imageUrls.length - 1 : prevIndex - 1
    );
  };

  const getTitle = (aFolder: string) => {
    if (aFolder === "pv1") {
      return "Warm Classy Apartment Near Downtown and the South Hill";
    }
    if (aFolder === "pv2") {
      return "Parkview Apartments 1309 W. 1st Ave Spokane, Washington, Apartment 603";
    }
    if (aFolder === "shh") {
      return "South Hill Heights Apartments 612 S Lincoln Spokane, Washington";
    }
    if (aFolder === "gv") {
      return "Greenview Apartments 815 S Lincoln Spokane, Washington";
    }
    return "";
  };

  const getSummary = (aFolder: string) => {
    if (aFolder === "pv1") {
      return "This fully-furnished apartment features unique and modern decor that is so comfortable, guests may never want to leave!  Each space features hand-picked local art, in-unit washer and dryer, a fully stocked kitchen, comfortable living room with a 55\" Smart TV, and keyless access -- all capped by the coziest bedroom nestled in a loft style, vintage apartment! Just out the door, guests will find themselves in the heart of Spokane's entertainment district with music, breweries and so much more!";
    }
    if (aFolder === "pv2") {
      return "This beautifully decorated home sits atop the 6th floor, offering spectacular views alongside everything you need for a comfortable stay. Hand-picked local art compliments a fully stocked kitchen, in-unit washer & dryer, 2 cozy bedrooms, a state-of-the-art fitness center, BBQ area, mid-level Rooftop patio, and so much more that you'll never want to leave! But in case you do, you'll find yourself in the heart of Spokane's entertainment district with music, breweries, and more just outside!";
    }
    if (aFolder === "shh") {
      return "Enjoy this fully furnished apartment that not only provides you with everything you need but incredibly thoughtful touches to make this place feel like home. Walking distance to hospitals, grocery stores and close to downtown! Right out the door, guests are minutes away from Spokanes entertainment district with music, brewery's and so much more! The space features on premise unit washer/dryer, queen bed, fully stocked kitchen, comfortable living room with 55\" smart tv and a private balcony!!";
    }
    if (aFolder === "gv") {
      return "Enjoy this fully furnished apartment that not only provides you with everything you need but incredibly thoughtful touches to make this place feel like home. Walking distance to hospitals, grocery stores and close to downtown! Right out the door, guests are minutes away from Spokanes entertainment district with music, brewery's and so much more!  The space features in-unit washer/dryer, queen bed,  fully stocked kitchen, comfortable living room with 55\" smart tv and a private balcony!!";
    }
    return "";
  };

  const getFFLink = (aFolder: string) => {
    if (aFolder === "pv1") {
      return "https://www.furnishedfinder.com/property/561185_1";
    }
    if (aFolder === "pv2") {
      return "https://www.furnishedfinder.com/property/596247_1";
    }
    if (aFolder === "shh") {
      return "https://www.furnishedfinder.com/property/596247_1";
    }
    if (aFolder === "gv") {
      return "https://www.furnishedfinder.com/property/753398_1";
    }
    return "";
  };

  const getBBLink = (aFolder: string) => {
    if (aFolder === "pv1") {
      return "https://www.airbnb.com/rooms/953022166816603683?source_impression_id=p3_1731880537_P31DFvBtb2PPI-gJ";
    }
    if (aFolder === "pv2") {
      return "https://www.airbnb.com/rooms/992784134649728153?guests=1&adults=1&s=67&unique_share_id=3086dd24-c455-4e91-800b-3f0655eead4a";
    }
    if (aFolder === "shh") {
      return "https://www.furnishedfinder.com/property/764788_1";
    }
    if (aFolder === "gv") {
      return "https://www.airbnb.com/rooms/1255292325374756200?guests=1&adults=1&s=67&unique_share_id=2c641d66-a003-4610-97fb-7411eb86435b";
    }
    return "";
  };

  const getCoordinates = (aFolder: string): LatLngTuple => {
    if (aFolder === "pv1") {
      return [47.65661282456526, -117.431679133461];
    }
    if (aFolder === "pv2") {
      return [47.65661282456526, -117.431679133461];
    }
    if (aFolder === "shh") {
      return [47.6588, -117.426];
    }
    if (aFolder === "gv") {
      return [47.64793688410548, -117.4242673757906];
    }
    return [47.6588, -117.426];
  };

  const getAddress = (aFolder: string) => {
    if (aFolder === "pv1") {
      return "1309 W. 1st Ave";
    }
    if (aFolder === "pv2") {
      return "1309 W. 1st Ave";
    }
    if (aFolder === "shh") {
      return "815 S lincoln";
    }
    if (aFolder === "gv") {
      return "612 S Lincoln St";
    }
    return "";
  };

  return (
    <div>
      <h4
        className="text-uppercase"
        style={{ textAlign: "center", marginBottom: "20px" }}
      >
        {getTitle(folderName)}
      </h4>
      <div style={{ padding: "5%" }}>
        {imageUrls.length > 0 ? (
          <div className="property-container">
            {/* Image Section */}
            <div className="property-image">
              <img
                src={imageUrls[currentIndex]}
                alt={`Slide ${currentIndex + 1}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
              <button
                onClick={prevImage}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "10px",
                  transform: "translateY(-50%)",
                  background: "rgba(0,0,0,0.5)",
                  color: "#fff",
                  border: "none",
                  borderRadius: "50%",
                  cursor: "pointer",
                  padding: "10px",
                }}
              >
                &#8592;
              </button>
              <button
                onClick={nextImage}
                style={{
                  position: "absolute",
                  top: "50%",
                  right: "10px",
                  transform: "translateY(-50%)",
                  background: "rgba(0,0,0,0.5)",
                  color: "#fff",
                  border: "none",
                  borderRadius: "50%",
                  cursor: "pointer",
                  padding: "10px",
                }}
              >
                &#8594;
              </button>
              {/* Links Section */}
              <div
                style={{
                  marginTop: "10px",
                  color: "blue",
                  textAlign: "center",
                }}
              >
                <a
                  href={getFFLink(folderName)}
                  style={{ textDecoration: "none", color: "blue" }}
                >
                  Furnished Finder
                </a>
                <br />
                <a
                  href={getBBLink(folderName)}
                  style={{ textDecoration: "none", color: "blue" }}
                >
                  AirBNB
                </a>
                <br />
                <a
                  href="home"
                  style={{ textDecoration: "none", color: "blue" }}
                >
                  Home Page
                </a>
              </div>
            </div>

            {/* Summary Section */}
            <div className="property-summary">
              <p>{getSummary(folderName)}</p>
            </div>
          </div>
        ) : (
          <p style={{ textAlign: "center" }}>No images found in the folder.</p>
        )}
      </div>
      {folderName && (
        <div style={{ marginTop: "20px", width: "100%", height: "400px" }}>
          <MapContainer
            center={getCoordinates(folderName)}
            zoom={15}
            style={{ width: "100%", height: "100%", borderRadius: "8px" }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <Marker position={getCoordinates(folderName)}>
              <Popup>{getAddress(folderName)}</Popup>
            </Marker>
          </MapContainer>
        </div>
      )}
    </div>
  );
};

export default Property;
